import anime from 'animejs/lib/anime.es.js';
import Sketch from "./app";
// import jQuery from "jquery";

import profile from "../img/profile.jpg";
import projectImg1 from "../img/projects/homehak.jpg";
// import t2 from "../img/capel.jpg";

import imgFragment from "./shader/fragment.glsl";
import imgVertex from "./shader/vertex.glsl";


import projectFragment from "./shader/projects/fragment.glsl";
import projectVertex from "./shader/projects/vertex.glsl";

let repeatNo = 0;


let profilePic = new Sketch({
    dom: document.getElementById("profile"),
    texture: profile,
    aspect: 1.3,
    vert: imgVertex,
    frag: imgFragment
  });


  

  function getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
}

  // SCROLL CALCULATOR START
  let container = document.getElementById("scroller");
  let s1 = document.getElementById('project__all');




  const scrollPercentFunction = () => {
    const bodyST = document.body.scrollTop;
    const docST = document.documentElement.scrollTop;
    const docSH = document.documentElement.scrollHeight;
    const docCH = document.documentElement.clientHeight;
  
    return ((docST + bodyST) / (docSH - docCH)) * 100;
  };
  
  var raf = null,
    timeout = null,
    scrollPercent = 0;
  
window.onscroll = function() {
    scrollPercent = scrollPercentFunction() / 100;
  
    if (null === raf) {
      raf = requestAnimationFrame(updateScroll);
    }
  };
  
  var updateScroll = function () {
    var progress = mainScroll.progress / 100;
    var proxyProgress = scrollPercent;
    var diff = proxyProgress - progress;
  
    profilePic.scrollDelta = Math.abs(diff); // glsl fragmentshader distort on scroll
    // project1.scrollDelta = Math.abs(diff); // glsl fragmentshader distort on scroll
  



    progress += (proxyProgress - progress) * 0.07;
    mainScroll.seek(progress * mainScroll.duration);
    parallaxScroll.seek(progress * mainScroll.duration);
    bgChange.seek(progress * mainScroll.duration);
    projectsIn.seek(progress * mainScroll.duration);
  
  
    //  console.log('updating:' + progress);
  
    if (Math.abs(diff) > 0.000005) {
      raf = requestAnimationFrame(updateScroll);
    } else if (raf) {
      cancelAnimationFrame(raf);
      raf = null;
    }



    
    // FADE IN - IN VIEW DETECTION

    // function inView(elementID){
    //   const style = window.getComputedStyle(document.getElementById(elementID));
    //   const matrix = style.transform || style.webkitTransform || style.mozTransform
    //   // Parse this string to obtain different attributes of the matrix.
    // // This regexp matches anything looks like this: anything(1, 2, 3, 4, 5, 6);
    // // Hence it matches both matrix strings:
    // // 2d: matrix(1,2,3,4,5,6)
    // // 3d: matrix3d(1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16);
    // var matrixPattern = /^\w*\((((\d+)|(\d*\.\d+)),\s*)*((\d+)|-(\d*\.\d+))\)/i;
    // var matrixValue = [];
    // if (matrixPattern.test(matrix)) { // When it satisfy the pattern.
    //     var matrixCopy = matrix.replace(/^\w*\(/, '').replace(')', '');
    //     // console.log(matrixCopy);
    //     matrixValue = matrixCopy.split(/\s*,\s*/);
    // }
    // return matrixValue;

    // }

    

    // let allProjects = inView('project__all');
    
    // if(repeatNo == 0 && allProjects[5] < -240){
    //   // console.log(allProjects[5]);
    //   repeatNo = 1;

    //   let projectsIn = anime({
    //     targets: ".project__title",
    //     translateY: ["350px", "0"],
    //     duration: 3000,
    //     easing: 'easeInOutQuint',
    //     delay: function (el, i, l) {
    //       return i * 100;
    //     },
    //   });

    // }


    
  };

  
  let projectsIn = anime({
    targets: ".project__title",
    translateY: ["150px", "0"],
    duration:21,
    skewX: ["45deg","0deg"],
    easing: 'easeInOutQuint',
    delay: function (el, i, l) {
      return i * 2;
    },
  });

  // SCROLL CALCULATOR END


// anime({
//   targets: '.loadscreen',
//   opacity: [1,0],
//   // translateY:["0vh","-100vh"],
//   duration: 10,
//   easing: 'easeInOutQuart',
//   delay: 0
// });

 anime({
    targets: profilePic.settings,
    progress: [1,0],
    duration: 3000,
    // delay: 500,
    easing: 'cubicBezier(.82,.1,0,.97)'
  });


  let hoverProfile = anime({
    targets: profilePic.settings,
    mouseHover: [0.0,1],
    duration: 1000,
    easing: 'cubicBezier(.05,.58,.12,.97)',
    autoplay: false,
  });

  let profileID = document.getElementById("profile");

  function enterProfile() {
    if (hoverProfile.reversed) hoverProfile.reverse();
    hoverProfile.play();
  }
  
  function leaveProfile() {
    if (!hoverProfile.reversed) hoverProfile.reverse();
    hoverProfile.play();
  }
  
  profileID.addEventListener('mouseenter', enterProfile, false);
  profileID.addEventListener('mouseleave', leaveProfile, false);




anime({
    targets: profilePic.settings,
    scaleFactor: [0.3,1],
    duration: 4000,
    // delay: 500,
    easing: 'easeInOutQuint'
  });


// INTRO ANIMATIONS


anime({
  targets: ".t__line span",
  translateY: ["300px", "0"],
  skewX: ["45deg","0deg"],
  duration: 2000,
  easing: 'easeOutQuint',
  delay: function (el, i, l) {
    return i * 200;
  },
});

anime({
  targets: ".info .hr",
  width: ["0", "100%"],
  duration: 2000,
  easing: 'easeInOutExpo',
  delay: 1000,
});

anime({
  targets: [".info__freelance span",".info__links li a"],
  translateY: ["100px", "0"],
  duration: 3000,
  skewX: ["45deg","0deg"],
  easing: 'easeInOutQuint',
  delay: function (el, i, l) {
    return i * 100;
  },
});

anime({
  targets: [".orange-bg"],
  translateY: ["100%", "0%"],
  duration: 1500,
  easing: 'cubicBezier(.82,.1,0,.97)',
  // delay:1000
});



// .hero__text",".info",".project__content", ".about__section"
let mainScroll = anime({
  targets: ["#page"],
  duration: 50,
  translateY: [0, -2000],
  autoplay: false,
  easing: "linear",
  // delay: function (el, i, l) {
  //   return i * 1;
  // },
});

let parallaxScroll = anime({
  targets: ".profile",
  duration: 50,
  translateY: [0, 500],
  autoplay: false,
  easing: "linear",
  delay:0,
});

let bgChange = anime({
  targets: ".orange-bg",
  duration: 5,
  opacity: [1, 0],
  autoplay: false,
  easing: "linear",
  delay: 0,
});



// // MOUSE FOLLOW
// let activeMouseMove = 1;
// let projectImg = document.getElementById('project__img');

// let projectBox = document.getElementById('page');

// function onMouseMove(e){
// if (activeMouseMove == 1) {
//   anime({
//     targets: projectImg.style,
//     left: e.clientX - 94 +"px",
//     top: e.clientY - 125 +"px",
//     easing: "easeOutQuint",
//     duration: 30,
//   });
// }
// else {
//   return 0;
// } 
//   // console.log("x:"+mousePos.y/window.innerWidth,"y:"+e.pageY);

// }



// projectBox.addEventListener('mousemove', onMouseMove);


///////////////////////////////////////////////////////////////////////////////////////////////////////

var mouseRaf = null,
  timeout = null,
  scrollPercent = 0;
  let mousePosition = 0;

  let pageBox = document.querySelector('body');
  pageBox.addEventListener('mousemove', function(e) {
    // scrollPercent = scrollPercentFunction() / 100;

    let mousePosition = {
      x: e.clientX,
      y: e.clientY
    };
  // updateMousePosition(mousePosition);
  // console.log(mouseRaf )
    // raf = requestAnimationFrame(updateScroll);

  
});


function lerp(start, end, l) {
  return start + (end - start) * l;
}

// function updateMousePosition(mp) {
//   var progress = mp.x / window.innerWidth;
//   var proxyProgress = mousePosition;
//   var mouseDiff = progress;

//   // anime({
//   //   targets: project1,
//   //   scrollDelta: progress,
//   //   easing: "easeInOutQuint",
//   //   duration: 20,
//   // });

//   project1.scrollDelta = Math.abs(mouseDiff); // glsl fragmentshader distort on scroll
//   // project1.scrollDelta = Math.abs(diff); // glsl fragmentshader distort on scroll

//   mouseDiff = lerp(mouseDiff, 0, 0.001);

//   console.log(mouseDiff);


//   //  console.log('updating:' + progress);

//   if (Math.abs(mouseDiff) > 0.000005) {
//     progress= requestAnimationFrame(updateMousePosition);
//   } else if (mouseRaf) {
//     cancelAnimationFrame(mouseRaf);
//     mouseRaf = null;
//   }
// };

/////////////////////////////////////////////////////////////////////////////////////////

// PROJECTS EVENTS


// for(let i=1; i<=2;i++){
//   let projectIDcount = "project__image_" + i;
//   console.log(projectIDcount)
// }


let project1 = new Sketch({
  dom: document.getElementById("project__img"),
  texture: projectImg1,
  aspect: 0.73,
  vert: projectVertex,
  frag: projectFragment
});


anime({
  targets: project1.settings,
  progress: [1,0],
  duration: 3000,
  // delay: 500,
  easing: 'easeInOutQuint'
});



// // miiuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww (written by Tara)


let hoverProject = anime({
  targets: project1.settings,
  mouseHover: [1.,0.],
  duration: 2000,
  easing: 'easeOutExpo',
  autoplay: false,
});


let projectID1 = document.getElementById("project__1");

function enterProject1() {
  if (hoverProject.reversed) hoverProject.reverse();
  hoverProject.play();
}

function leaveProject1() {
  if (!hoverProject.reversed) hoverProject.reverse();
  hoverProject.play();
}

projectID1.addEventListener('mouseenter', enterProject1, false);
projectID1.addEventListener('mouseleave', leaveProject1, false);


// function projectOpen(){
//   anime({
//     targets: ".project__img",
//     width: ["188px","100vw"],
//     duration: 1000,
//     easing: 'easeOutExpo',
//     autoplay: false,
//   });
// }



// REVEAL PRojecT WIP

// document.getElementById("project__img").style.width = document.getElementById("project__img").offsetHeight * 0.75 + "px";
// console.log(document.getElementById("project__img").offsetHeight * 1.33);
// let halfHeight = window.innerHeight * 0.9 + "px";
// projectID1.removeEventListener('mouseleave', leaveProject1, false);

// document.getElementById("project__1").onclick = function projectOpen() {
//   activeMouseMove = 0;
//   anime({
//     targets: ".project__img",
//     height: halfHeight,
//     left: "5vw",
//     top: "5vh",
//     duration: 1000,
//     easing: 'easeInOutExpo',
//     complete: function(anim) {
//       document.getElementById("project__img").style.width = document.getElementById("project__img").offsetHeight * 0.75 + "px";
//     },
//   });
// }

