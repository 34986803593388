import * as THREE from "three";
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

// import textFragment from "./shader/text/fragment.glsl";
// import textVertex from "./shader/text/vertex.glsl";

// import imgFragment from "./shader/fragment.glsl";
// import imgVertex from "./shader/vertex.glsl";


// import projectFragment from "./shader/projects/fragment.glsl";
// import projectVertex from "./shader/projects/vertex.glsl";



import * as dat from "dat.gui";

// import t from "../img/hello.png";
// import t2 from "../img/hello.png";

// import { TimelineMax } from "gspa";
// let OrbitControls = require("three-orbit-controls")(THREE);

export default class Sketch {
    constructor(options) {
        this.scene = new THREE.Scene();

        this.type = options.type;
        this.container = options.dom;
        this.texture = options.texture;
        this.aspect = options.aspect;
        this.frag = options.frag;
        this.vert = options.vert;
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio * 1.5);
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0x000000, 0); 
        this.renderer.physicallyCorrectLights = true;
        this.renderer.outputEncoding = THREE.sRGBEncoding;


        
        this.container.appendChild(this.renderer.domElement);

        var frustumSize = 1;
        // var aspect = 1/4;
        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000);

        this.camera.position.set(0, 0, 2);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.time = 0;

        this.isPlaying = true;

        this.addObjects(this.texture, this.frag, this.vert);
        this.resize();
        this.render();
        this.setupResize();
        this.settings();
        
    }
settings() {
    let that = this;
    this.settings = {
        progress: 0,
        scaleFactor: 0,
        mouseHover: 0,
        frag: "imgFragment",
        vert: "imgVertex"
    }
    // this.gui = new dat.GUI();
    // this.gui.add(this.settings, "progress", 0, 1, 0.01);
}

setupResize(){
    window.addEventListener("resize", this.resize.bind(this));
}

resize(){
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetWidth*this.aspect;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    //image cover
    this.imageAspect = this.aspect;
    let a1; let a2;
    if(this.height/this.width>this.imageAspect){
        a1 = (this.width/this.height) * this.imageAspect;
        a2 = 1;
    } else{
        a1 = 1;
        a2 = (this.height/this.width) / this.imageAspect;
    }
    this.material.uniforms.resolution.value.x = this.width;
    this.material.uniforms.resolution.value.y = this.height;
    this.material.uniforms.resolution.value.z = a1;
    this.material.uniforms.resolution.value.w = a2;

    this.camera.updateProjectionMatrix();
}


addObjects(img, frag, vert){
    let that = this;

    let tt = new THREE.TextureLoader().load(img);
    tt.magFilter = THREE.LinearFilter;
    tt.minFilter = THREE.LinearFilter;
    this.material = new THREE.ShaderMaterial({
        extensions: {
            derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        side: THREE.DoubleSide,
        uniforms: {
            time: { type: "f", value: 0 },
            progress: { type: "f", value: 0 },
            scaleFactor: { type: "f", value: 0 },
            scrollDelta: { type: "f", value: 0 },
            mouseHover: { type: "f", value: 0 },
            texture1: { type: "t", value: tt },
            resolution: { type: "v4", value: new THREE.Vector4() },
            uvRate1: {
                value: new THREE.Vector2(1, 1)
            }
        },
        // vertexShader: this.type=="text"?textVertex:imgVertex,
        // fragmentShader: this.type=="text"?textFragment:imgFragment ,       
        vertexShader: vert,
        fragmentShader: frag
    });
    
    this.geometry = new THREE.PlaneGeometry(1,1,1,1);
    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
}

stop(){
    this.isPlaying = false;
}

play(){
    if(!this.isPlaying){
        this.render();
        this.isPlaying = true;
    }
}

render(){
    if(!this.isPlaying) return;
    // this.time += 0.5;

    this.resize(); // if we need to resize on click or some event
    
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.scrollDelta.value = this.scrollDelta;
    this.material.uniforms.progress.value = this.settings.progress;
    this.material.uniforms.scaleFactor.value = this.settings.scaleFactor;
    this.material.uniforms.mouseHover.value = this.settings.mouseHover;
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);    
}


}

